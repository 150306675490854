import httpClient from '../api/httpClient'

const requestPdfQuoteModal = async (
  designId: number | string,
  includeMachineLogicSoftware = false,
  includeRobotPartnerParts = true
): Promise<void> => {
  const response = await httpClient('/quote_checkout_informations', {
    method: 'POST',
    params: { design_id: designId },
    headers: {
      Accept: 'text/vnd.turbo-stream.html',
    },
    data: {
      quote_checkout_information: { single_item: true },
      cart_design: {
        include_machine_logic_programs: includeMachineLogicSoftware,
        include_robot_partner_parts: includeRobotPartnerParts,
      },
    },
  })

  document.body.insertAdjacentHTML('beforeend', response.data)
  const quoteCheckoutModal: HTMLDivElement | null = document.querySelector(
    "[data-modal-id='quote-checkout']"
  )

  if (quoteCheckoutModal) {
    quoteCheckoutModal.hidden = false
    document.body.style.overflowY = 'hidden'
  }
}

export default requestPdfQuoteModal
