import moment from 'moment'

export function timeSince(
  date: moment.MomentInput,
  format: moment.unitOfTime.Diff = 'days'
): number {
  const dateCreated = moment(date)
  const today = moment()
  return today.diff(dateCreated, format)
}

export const monthName = (monthIndex = 1, locale = 'en-US'): string => {
  const date = new Date()
  date.setMonth(monthIndex)
  const options = { month: 'long' }
  return date.toLocaleString(locale, options)
}

export const shortDate = (
  dateValue: null | undefined | string | Date,
  locale = 'en-US',
  timeZone = 'UTC'
): string => {
  if (!dateValue) {
    return ''
  }

  const options = { timeZone, year: 'numeric', month: 'long', day: 'numeric' }
  const date = new Date(dateValue)

  return date.toLocaleDateString(locale, options)
}

export const shorterDate = (
  dateValue: null | undefined | string | Date,
  locale = 'en-US',
  timeZone = 'UTC'
): string => {
  if (!dateValue) {
    return ''
  }

  const options = { timeZone, month: 'long', day: 'numeric' }
  const date = new Date(dateValue)

  return date.toLocaleDateString(locale, options)
}

export const dateString = (date?: Date | string): string =>
  moment(date || new Date())
    .format('MM-DD-YYYY')
    .toLowerCase()

export const dateInputString = (date?: Date | string): string => {
  if (!date) {
    return ''
  }

  return moment(date || new Date())
    .format('YYYY-MM-DD')
    .toLowerCase()
}

export const dateRangeString = (
  start: null | undefined | string | Date,
  end: null | undefined | string | Date,
  locale = 'en-US',
  timeZone = 'UTC'
): string => {
  if (!start || !end) {
    return ''
  }

  const startDate = new Date(start)
  const endDate = new Date(end)

  const startOptions = { timeZone, month: 'long', day: 'numeric' }
  const endOptions = { timeZone, day: 'numeric' }

  if (startDate.getUTCFullYear() !== endDate.getUTCFullYear()) {
    startOptions['year'] = 'long'
    endOptions['year'] = 'long'
  }

  if (startDate.getUTCMonth() !== endDate.getUTCMonth()) {
    endOptions['month'] = 'long'
  }

  const startString = startDate.toLocaleDateString(locale, startOptions)
  const endString = endDate.toLocaleDateString(locale, endOptions)

  return `${startString} - ${endString}`
}

export function assembleTimeToFormat(duration: moment.DurationInputArg1): string {
  const assemble_time = moment.duration(duration, 'seconds')
  const hours = Math.floor(assemble_time.asHours())
  const mins = Math.floor(assemble_time.asMinutes()) - hours * 60
  const formattedMinutes = mins > 9 ? `${mins}` : `0${mins}`
  return `${hours}:${formattedMinutes} Hrs`
}

export function isDateToday(original: Date) {
  const now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

  const originalTime = original.getTime()
  const nowTime = now.getTime()

  const diff = originalTime - nowTime

  if (Number.isNaN(diff)) {
    return false
  }

  return diff === 0
}

export function daysInWeeks(days: number, roundNearestWeek = true) {
  const weeks = days / 7

  if (!roundNearestWeek) return weeks

  return Math.round(weeks)
}

export const addBusinessDays = (date, daysToAdd) => {
  const WEEKEND = [moment().day('Saturday').weekday(), moment().day('Sunday').weekday()]
  let daysAdded = 0
  let momentDate = moment(new Date(date))
  while (daysAdded < daysToAdd) {
    momentDate = momentDate.add(1, 'days')
    if (!WEEKEND.includes(momentDate.weekday())) {
      daysAdded++
    }
  }

  return momentDate
}
