import { daysInWeeks } from './Dates'

export function getPartNumber(pPartNumberWithVersion: string): string {
  const partNumber = pPartNumberWithVersion.match(/([\w]{2})-([\w]{2})-([0-9]{3})-([0-9]{4})/)
  return partNumber ? partNumber[0] : pPartNumberWithVersion
}

export function getPartVersion(pPartNumber: string): string {
  const lPartVersion = pPartNumber.match(
    /([\w]{2})-([\w]{2})-([0-9]{3})-([0-9]{4})(__([0-9]{1,3}))/
  )
  if (!lPartVersion || lPartVersion.length != 7) {
    return '1'
  } else {
    return lPartVersion[6]
  }
}

export function getPartVersionAndNumberString(pPartNumberWithVersion: string): string {
  const lVersion = getPartVersion(pPartNumberWithVersion)
  const lVersionSuffix = lVersion === '1' ? '' : ` v${lVersion}`
  return getPartNumber(pPartNumberWithVersion) + lVersionSuffix
}

export function getLeadTimeText(shipmentDelay: number): string {
  if (shipmentDelay > 18) {
    const weeks = daysInWeeks(shipmentDelay)
    return `${weeks} weeks`
  }

  const daysText = shipmentDelay == 1 ? 'day' : 'days'
  return `${shipmentDelay} ${daysText}`
}
